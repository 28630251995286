@import "~antd/dist/antd.less";

@primary-color: #14213d;
@error-color: #ef4444;
@warning-color: #fca311;
@success-color: #22c55e;
@heading-color: #14213d;
@disabled-color: #e5e5e5;
@text-color-secondary: #fca311;
@layout-header-background: #14213d;
@menu-dark-item-active-bg: #6f9ec9;
@disabled-bg: @disabled-color;

#root,
.main-wrapper {
  height: 100%;
}

.sidebar-logo-wrapper {
  height: 64px;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
}

.ant-menu-item {
  margin-top: 0 !important;
}

.header-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @white;
  padding: 0 24px 0 0;

  .sidebar-toggle {
    cursor: pointer;
    transition: color 0.3s;
    color: @white;
    font-size: 24px;
  }
}

.content-layout {
  padding: 24px;
  background-color: @white;
  overflow-y: auto;
  height: 100%;
}

.list-header {
  display: flex;
  flex-direction: column;
}

@media (min-width: @screen-sm-min) {
  .list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & h1 {
      margin-bottom: 0;
    }
  }
}

.auth-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.login-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .logo {
    max-width: 300px;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timetable-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid @primary-color;
}

.timetable-row {
  display: flex;
  border: 1px solid @primary-color;
}

.timetable-time {
  padding: 10px 20px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-right: 2px solid @primary-color;
}

.timetable-slot-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.timetable-add-btn {
  margin: 10px;
  max-width: 200px;
}

.timetable-lesson-wrapper {
  margin: 15px;
  padding: 10px;
  border: 1px solid @primary-color;
  position: relative;
}

.timetable-lesson-delete-btn {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  font-size: 24px;
  z-index: 10;
}

.timetable-lesson-subject,
.timetable-lesson-teacher {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px 10px 0 10px;
}

.timetable-form-field {
  width: 100%;
  margin-bottom: 8px;
  .ant-form-item-label {
    padding: 0;
  }
}

.select-field-array-wrapper {
  padding: 10px;
  border: 1px solid @primary-color;

  .single-field-wrapper {
    display: flex;
    gap: 10;
  }
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.ml-auto {
  margin-left: auto;
}

.dot {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.categories-selection {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ant-table-filter-dropdown-tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper {
  &,
  &:hover {
    background: white;
  }
}
